import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Observable,
  Subject,
  catchError,
  distinctUntilChanged,
  interval,
  map,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';

import { AppCoreMaintenanceService } from '../../../../../core/maintenance/src/lib/app-core-maintenance/app-core-maintenance.service';

@Injectable({
  providedIn: 'root',
})
export class AppSharedUtilAuthService {
  private trigger$: Subject<void> = new Subject<void>();

  /**
   * Immediately start checking if the user is authenticated and then check every 30 seconds.
   * Share the last emitted value with subscribers to prevent multiple requests.
   * trigger$ is used to reset the interval when needed.
   */
  isAuthenticated$: Observable<boolean> = this.trigger$.pipe(
    startWith(undefined), // Start immediately when subscribed
    switchMap(() =>
      interval(30000).pipe(
        startWith(0),
        switchMap(() =>
          this.loadAuthenticated().pipe(
            map(({ isAuthenticated }) => isAuthenticated),
          ),
        ),
      ),
    ),
    shareReplay(1), // Share the last emitted value with subscribers
  );

  constructor(
    private readonly router: Router,
    private readonly http: HttpClient,
    private readonly maintenanceService: AppCoreMaintenanceService,
  ) {
    this.isAuthenticated$
      .pipe(distinctUntilChanged())
      .subscribe((isAuthenticated) => {
        if (!isAuthenticated) {
          this.logout();
        }
      });
  }

  private loadAuthenticated(): Observable<{ isAuthenticated: boolean }> {
    return this.http.get<{ isAuthenticated: boolean }>('/api/auth/check').pipe(
      catchError(() => {
        this.maintenanceService.handleMaintenanceMode();
        return [{ isAuthenticated: false }];
      }),
    );
  }

  resetAuthentication(): void {
    this.trigger$.next();
  }

  logout(): void {
    this.http.post('/api/auth/logout', undefined).subscribe(() => {
      this.resetAuthentication();
      this.router.navigate(['/auth/login']);
    });
  }
}
