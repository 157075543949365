import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorResponse } from '@apollo/client/link/error';
import { BehaviorSubject } from 'rxjs';

export interface MaintenanceState {
  isInMaintenance: boolean;
  hasError: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AppCoreMaintenanceService {
  private maintenanceState = new BehaviorSubject<MaintenanceState>({
    isInMaintenance: false,
    hasError: false,
  });
  private retryTimer: any;
  private retryCount = 0;
  private maxRetries = 12; // 1 minutes with 5-second intervals

  constructor(private http: HttpClient) {}

  handler = this.errorHandler.bind(this);

  errorHandler(errorResponse: ErrorResponse): void {
    const { networkError } = errorResponse;

    if (networkError) {
      const error = networkError as any;
      if (error.status === 502 || error.status === 500) {
        this.handleMaintenanceMode();
      }
    }
  }

  handleMaintenanceMode() {
    this.maintenanceState.next({
      isInMaintenance: true,
      hasError: false,
    });
    this.startRetrying();
  }

  private startRetrying() {
    if (this.retryTimer) {
      clearInterval(this.retryTimer);
    }

    this.retryCount = 0;
    this.retryTimer = setInterval(() => {
      this.retryCount++;

      this.checkServerHealth().then((isHealthy) => {
        if (isHealthy) {
          this.handleServerRecovery();
        } else if (this.retryCount >= this.maxRetries) {
          this.handleMaxRetriesExceeded();
        }
      });
    }, 5000);
  }

  private async checkServerHealth(): Promise<boolean> {
    try {
      const response = await this.http
        .get('/api/health/check', {
          observe: 'response',
        })
        .toPromise();

      return response?.status === 200;
    } catch {
      return false;
    }
  }

  private handleServerRecovery() {
    clearInterval(this.retryTimer);
    this.maintenanceState.next({
      isInMaintenance: false,
      hasError: false,
    });
  }

  private handleMaxRetriesExceeded() {
    clearInterval(this.retryTimer);
    this.maintenanceState.next({
      isInMaintenance: false,
      hasError: true,
    });
  }

  refreshPage() {
    window.location.reload();
  }

  get maintenanceState$() {
    return this.maintenanceState.asObservable();
  }
}
