import { InjectionToken } from '@angular/core';
import { ApolloLink } from '@apollo/client';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { HotToastService } from '@ngxpert/hot-toast';

export const ERROR_LINK = new InjectionToken('Error Link');
export const ERROR_LINK_HANDLERS = new InjectionToken('Error Link Handlers');

export type ErrorLink = ApolloLink;

export type ErrorLinkHandler = (errorResponse: ErrorResponse) => void;

export function createErrorLink(
  toast: HotToastService,
  additionalHandlers: ErrorLinkHandler[] = [],
): ErrorLink {
  return onError((errorResponse) => {
    const { graphQLErrors, networkError } = errorResponse;

    if (graphQLErrors) {
      graphQLErrors.map(({ message }) => {
        toast.error(message);
      });
    }

    if (networkError) {
      toast.error(networkError.message);
    }

    additionalHandlers.forEach((handler) => handler(errorResponse));
  });
}
