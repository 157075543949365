// Originally from here: https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts#L33-L49
// A basic field policy that uses options.args.{offset,limit} to splice
// the incoming data into the existing array. If your arguments are called
// something different (like args.{start,count}), feel free to copy/paste
// this implementation and make the appropriate changes.
import { FieldPolicy, Reference } from '@apollo/client';

import {
  PaginateInputInterface,
  PaginatedInterface,
} from '@husky/shared-util-paginated';

export function offsetLimitPagination<T = Reference>(
  keyArgs: FieldPolicy<any>['keyArgs'] = false,
): FieldPolicy<PaginatedInterface<T>> {
  return {
    keyArgs,
    merge(existing, incoming, { args }) {
      const merged = existing && existing.data ? existing.data.slice(0) : [];
      const incomingData = incoming && incoming.data ? incoming.data : [];
      const paginateArgs = args?.['paginate'] as
        | PaginateInputInterface
        | undefined;

      if (incomingData.length > 0) {
        if (paginateArgs) {
          // Assume an offset of 0 if args.offset omitted.
          const { offset = 0 } = paginateArgs ?? {};

          // Ensure the array isn't longer than the total count
          if (incoming.meta?.totalCount !== undefined) {
            merged.length = Math.min(merged.length, incoming.meta.totalCount);
          }

          // Insert the new data at the correct offset
          merged.splice(offset, incomingData.length, ...incomingData);
        } else {
          // It's unusual (probably a mistake) for a paginated field not
          // to receive any arguments, so you might prefer to throw an
          // exception here, instead of recovering by appending incoming
          // onto the existing array.
          merged.push(...incomingData);
        }
      }

      return {
        data: merged,
        meta: incoming.meta,
      };
    },
  };
}
