import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[huskyButtonIcon]',
  standalone: true,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'husky-button__icon',
  },
})
export class ButtonIconDirective {
  @Input('huskyButtonIcon') position: 'start' | 'end' = 'start';

  @HostBinding('class.husky-button__icon--start')
  get isPositionStart(): boolean {
    return this.position === 'start';
  }

  @HostBinding('class.husky-button__icon--end')
  get isPositionEnd(): boolean {
    return this.position === 'end';
  }

  constructor() {}
}
