import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[huskyButton]',
  standalone: true,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'husky-button',
  },
})
export class ButtonDirective {
  @Input() color: 'primary' | 'primary-light' | 'outlined' | 'danger' =
    'outlined';
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() disabled: boolean = false;

  @HostBinding('class.husky-button--primary')
  get isColorPrimary(): boolean {
    return this.color === 'primary';
  }

  @HostBinding('class.husky-button--primary-light')
  get isColorPrimaryLight(): boolean {
    return this.color === 'primary-light';
  }

  @HostBinding('class.husky-button--outlined')
  get isColorOutlined(): boolean {
    return this.color === 'outlined';
  }

  @HostBinding('class.husky-button--danger')
  get isColorDanger(): boolean {
    return this.color === 'danger';
  }

  @HostBinding('class.husky-button--lg')
  get isSizeLarge(): boolean {
    return this.size === 'lg';
  }

  @HostBinding('class.husky-button--sm')
  get isSizeSmall(): boolean {
    return this.size === 'sm';
  }

  @HostBinding('class.husky-button--disabled')
  get isDisabled(): boolean {
    return this.disabled;
  }

  /** This makes the button not clickable */
  @HostBinding('attr.disabled')
  get isDisabledAttr(): boolean | null {
    return this.disabled ? true : null;
  }
}
