import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[huskyIconButton]',
  standalone: true,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'husky-icon-button',
  },
})
export class IconButtonDirective {
  @Input() size: '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'md';
  @Input() color:
    | 'primary'
    | 'primary-light'
    | 'outlined'
    | 'danger'
    | 'default' = 'default';

  @HostBinding('class.husky-button--primary')
  get isColorPrimary(): boolean {
    return this.color === 'primary';
  }

  @HostBinding('class.husky-button--primary-light')
  get isColorPrimaryLight(): boolean {
    return this.color === 'primary-light';
  }

  @HostBinding('class.husky-button--outlined')
  get isColorOutlined(): boolean {
    return this.color === 'outlined';
  }

  @HostBinding('class.husky-button--danger')
  get isColorDanger(): boolean {
    return this.color === 'danger';
  }

  @HostBinding('class.husky-icon-button--xl')
  get isSizeExtraLarge(): boolean {
    return this.size === 'xl';
  }

  @HostBinding('class.husky-icon-button--lg')
  get isSizeLarge(): boolean {
    return this.size === 'lg';
  }

  @HostBinding('class.husky-icon-button--sm')
  get isSizeSmall(): boolean {
    return this.size === 'sm';
  }

  @HostBinding('class.husky-icon-button--xs')
  get isSizeXs(): boolean {
    return this.size === 'xs';
  }

  @HostBinding('class.husky-icon-button--2xs')
  get isSize2xs(): boolean {
    return this.size === '2xs';
  }
}
