import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonIconDirective } from './button-icon/button-icon.directive';
import { ButtonDirective } from './button/button.directive';
import { IconButtonDirective } from './icon-button/icon-button.directive';

@NgModule({
  imports: [
    CommonModule,
    ButtonDirective,
    IconButtonDirective,
    ButtonIconDirective,
  ],
  exports: [ButtonDirective, ButtonIconDirective, IconButtonDirective],
})
export class AppSharedUiButtonModule {}
