import { NgModule } from '@angular/core';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import { RetryLink } from '@apollo/client/link/retry';
import { HotToastService } from '@ngxpert/hot-toast';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import {
  ERROR_LINK,
  ERROR_LINK_HANDLERS,
  ErrorLink,
  ErrorLinkHandler,
  createErrorLink,
} from './error-link';
import result from './graphql-codegen';
import { offsetLimitPagination } from './offsetLimitPagination';

const uri = '/api/graphql';

export function createApollo(
  httpLink: HttpLink,
  errorLink: ErrorLink,
): ApolloClientOptions<unknown> {
  const retryLink = new RetryLink();

  return {
    link: ApolloLink.from([errorLink, retryLink, httpLink.create({ uri })]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            activities: offsetLimitPagination(['filter']),
            contacts: offsetLimitPagination(['filter']),
            projects: offsetLimitPagination(['filter']),
            timeEntryPaginated: offsetLimitPagination(['filter']),
          },
        },
      },
      possibleTypes: result.possibleTypes,
    }),
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
      },
      mutate: {
        fetchPolicy: 'network-only',
      },
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
    connectToDevTools: true,
  };
}

@NgModule({
  imports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, ERROR_LINK],
    },
    {
      provide: ERROR_LINK,
      useFactory: (
        toast: HotToastService,
        additionalErrorLinkHandlers: ErrorLinkHandler[],
      ) => createErrorLink(toast, additionalErrorLinkHandlers),
      deps: [HotToastService, ERROR_LINK_HANDLERS],
    },
  ],
  exports: [],
})
export class AppSharedDataAccessModule {}
